import styled from 'styled-components'
export const Wrapper = styled.div`
  .form-check-input:checked {
    background-color: var(--second-color);
    border-color: var(--second-color);
  }
  .tab-header {
    transform: rotate(22deg);
  }
`
