import styled from 'styled-components'
export const Wrapper = styled.div`
  .link-style {
    color: var(--second-color);
    cursor: pointer;
  }

  .link-style:hover {
    color: #cb8700;
  }
`
