import styled, { css } from 'styled-components'

export const Wrapper = styled.span`
  .label {
    /* font-weight: bold; */
    padding: 7px 12px;
    border-radius: 5px;
    font-size: var(--smallFont);
    /* background-color: var(--primary1); */
  }
`
