export const WarningType = {
  1: 'success',
  2: 'Information',
  3: 'Warning',
  4: 'Error',
}
export const WarningVariant = {
  1: 'success',
  2: 'info',
  3: 'warning',
  4: 'danger',
}
